import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SeO from "../components/seo"

import { css } from "@emotion/react"

import Header from "../components/header"
import Form from "../components/form"

import { MdDomain, MdPlace } from "react-icons/md"
import Product from "../components/product"

const ProductionTemplate = (props) => {
    const lang = props.pageContext.lang
    const title = props.data.production.data.title.text
    const subtitle = props.data.production.data.subtitle.text
    const desc = props.data.production.data.desc
    const videoEmbed = props.data.production.data.video && props.data.production.data.video.html
    const story = props.data.production.data.story
    const client = props.data.production.data.client.document
    const client_place = client ? client.data.place : null
    const quotes = props.data.production.data.quotes
    const place = props.data.production.data.place || client_place || 'Suisse romande'
    const thumb_image = props.data.production.data.image
    const hide_image = props.data.production.data.hide_image
    const products = props.data.production.data.parent_products

    // console.log(props)

    return (
        <Layout>
            <SeO 
                title={`${title} - Lyketil Agence Digitale Créative à Lausanne`}
                description={desc}
                lang={lang}
                slug={props.uri}
            />

            <Header 
                title={title}
                subtitle={subtitle}
                intro={desc}
                small
                indent={videoEmbed || thumb_image.fluid ? true : false}
                client={client}
                place={place}
                lang={lang}
            />

            <div className="bg--white">
                {thumb_image.fluid && !hide_image && 
                    <section css={imageSection}>
                        <div className="container content-indent--up">
                            <Img fluid={thumb_image.fluid} />
                        </div>
                    </section>
                }

                {videoEmbed && 
                    <section css={videoSection}>
                        <div className={(thumb_image.fluid && !hide_image) ? "container" : "container content-indent--up"}>
                            <div dangerouslySetInnerHTML={{ __html: videoEmbed }} className="video-container" />
                        </div>
                    </section>
                }

                {quotes[0] && quotes[0].quote.text && 
                    <section css={quoteSection} className="container">
                        {quotes.map( quote => (
                            <div className="single-quote" key={quote.quote.text}>
                                <blockquote>{quote.quote.text}</blockquote>
                                <div className="quote-meta">
                                    {quote.author && <div><MdDomain /> <span>{quote.author}</span> {quote.author_position}</div>}
                                    <div><MdPlace /> {place}</div>
                                </div>
                            </div>
                        ))}
                    </section>
                }

                {products && 
                    <section css={productsSection}>
                        <div className="container">
                            <span className="section-subtitle">PRESTATIONS</span>
                            <h2>Ce mandat fait appel à notre savoir-faire digital</h2>
                        </div>

                        <div css={productsList} className={products.length > 1 ? "two-col-grid" : "container"}>
                            {products.map( item => {
                                const product = item.linked_product.document
                                const category = product && product.data.category.document

                                if ( product ) {
                                    return (
                                        <Product 
                                            uid={product.uid}
                                            title={product.data.title.text}
                                            subtitle={category && category.data.title.text}
                                            desc={product.data.desc}
                                            key={product.uid}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </section>
                }

                {story[0].step_name.text &&
                    <section css={storySection}>
                        <div className="container">
                            <span className="section-subtitle">BEHIND THE SCENES</span>
                            <h2>La #story du mandat</h2>
                        </div>

                        {story.map( step => (
                            <div key={step.step_name.text}>
                                {step.step_image && 
                                    <div className="container--wide">
                                        <Img fluid={step.step_image.fluid} />
                                    </div>
                                }
                                <div css={storyCaption} className="container">
                                    <h3>{step.step_name.text}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: step.step_desc.html }} />
                                </div>
                            </div>
                        ))}
                    </section>
                }
            </div>

            <section className="container">
                <Form formName={title} />
            </section>
        </Layout>
    )
}

export default ProductionTemplate

const videoSection = css`
    // padding-bottom: 70px;
`

const imageSection = css`
    // padding-bottom: 70px;
`

const productsSection = css`
    background-color: #f7f7f7;
    padding: 70px 0;
    margin-top: 50px;
`

const productsList = css`
    padding: 20px 20px 10px;
`

const quoteSection = css`
    margin-top: 30px;
    color: white;
    margin-bottom: 70px;

    .single-quote {
        background-color: #101d3f;
        margin-bottom: 10px;
        padding: 20px;
        border-radius: 5px 20px 20px 20px;
        font-family: 'Courier Prime', Courier, monospace;

        blockquote {
            margin: 0 0 10px;
            padding: 0;
            font-size: 1.4rem;
        }

        .quote-meta {
            font-size: .8rem;
            opacity: .8;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                margin-bottom: -2px;
            }

            span {
                font-weight: 800;
            }
        }
    }
`

const storySection = css`
    padding: 70px 0;
`

const storyCaption = css`
    margin-top: 20px;
    margin-bottom: 70px;
`

export const productionTemplateQuery = graphql`
    query ProductionTemplateQuery($slug: String!) {
        production: prismicProductions(uid: {eq: $slug}) {
            uid
            data {
                title {
                    text
                }
                subtitle {
                    text
                }
                desc
                video {
                    html
                }
                image {
                    fluid(imgixParams: {q: 100}, maxWidth: 1000) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                hide_image
                story {
                    step_name {
                      text
                    }
                    step_image {
                        fluid(imgixParams: {q: 100}, maxWidth: 1000) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    step_desc {
                      html
                    }
                }
                client {
                    document {
                      ... on PrismicClients {
                        uid
                        data {
                          name {
                            text
                          }
                          place
                        }
                      }
                    }
                }
                quotes {
                    quote {
                      text
                    }
                    author_position
                    author
                }
                place
                parent_products {
                    linked_product {
                      document {
                        ... on PrismicProducts {
                          uid
                          data {
                            title {
                              text
                            }
                            desc
                            category {
                                document {
                                  ... on PrismicCategories {
                                    data {
                                      title {
                                        text
                                      }
                                    }
                                    uid
                                  }
                                }
                            }
                          }
                        }
                      }
                    }
                }
            }
        }
    }
`